import _groupBy from 'lodash/fp/groupBy'
import {ROUTES as DashboardRoutes} from './Dashboard'
import ReviewsRoutes from './Reviews'
import ProofOfReviewsRoutes from './ProofOfReviews';
import ProductsRoutes from './Products';
import WarrantiesRoutes from './Warranties';

const routes = [
  ...DashboardRoutes,
  ...ReviewsRoutes,
  ...ProofOfReviewsRoutes,
  ...ProductsRoutes,
  ...WarrantiesRoutes,
]

export const modulesRoutes = _groupBy((({isPublic}) => isPublic ? 'publicPages' : 'privatePages'), routes)
