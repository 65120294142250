import Logo from 'components/Logo';
import { Sider } from 'ctrwowShared/components/ui/Layout';
import React, { useCallback, useState } from 'react';
import MainMenu from '../MainMenu';
import UserInfo from '../UserInfo';

import styles from './LeftSide.module.scss';
import appStyles from 'app-variables.scss';

const LeftSide = () => {
  const [collapsed, set_collapsed] = useState(false);

  const toggleCollapsed = useCallback(() => set_collapsed(!collapsed), [collapsed]);
  return (
    <Sider
      collapsible
      className={styles.ctrComp}
      collapsed={collapsed}
      onCollapse={toggleCollapsed}
      collapsedWidth={appStyles.ctrLayoutSidebarCollapsedWidth}
      width={appStyles.ctrLayoutSidebarWidth}
    >
      <Logo isMini={collapsed} className={'dfoMar__base--b'}/>
      <MainMenu isMini={collapsed}/>
      <UserInfo isMini={collapsed}/>
    </Sider>
  );
};

LeftSide.propTypes = {};

export default LeftSide;



