import React, {useMemo, memo} from 'react'
import classNames from 'classnames'
import Tooltip from 'ctrwowShared/components/ui/Tooltip'
import {injectIntl} from 'react-intl'
import {Link} from 'react-router-dom'

import styles from './MainMenuItem.module.scss'


const MainMenuItem = (props) => {
  // eslint-disable-next-line react/prop-types
  const {intl, menuItem: {labelId, path = '', icon: IconComp}, mode, isSubItem, isSideBarMini} = props
  const Tag = path ? Link : 'span'

  const menuName = useMemo(() => intl.formatMessage({id: labelId}), [labelId])

  return (<Tooltip title={menuName.length > 15 ? menuName : null} placement="right">
    <div className={classNames(styles.ctrComp, styles[`ctrComp--${mode}`], {[styles.ctrComp__subItem]: isSubItem})}>
      {IconComp ? (<IconComp className={styles.ctrComp__icon}/>) : null}
      {(isSideBarMini && !isSubItem) ? null : <Tag className={styles.ctrComp__text} to={path}>{menuName}</Tag>}
    </div>
  </Tooltip>)
}

MainMenuItem.propTypes = {}

export default injectIntl(memo(MainMenuItem))



