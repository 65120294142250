/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import _map from 'lodash/fp/map';
import React, { useMemo } from 'react';
import { Switch, Route } from 'react-router-dom';

import NotFoundPage, { NoPermissionLayout } from 'containers/CommonPages';
import PrivatePage from './PrivatePage';
import RouteWrapper from './RouteWrapper';
import ToastMessage from 'ctrwowShared/components/shared/ToastMessage';
import DocumentHead from './DocumentHead';
import Spin from 'antd/es/spin';
import { useSelector } from 'react-redux';
import { stateManager } from './store';

export default function App({ pageRoutes }) {
  const noPermissionLayoutConfig = useMemo(() => ({ isUsedAppShell: false }), []);
  const privateRoutes = useMemo(() => _map(
    (route) => <RouteWrapper key={route.path}  {...route}/>,
    pageRoutes.privatePages)
    , []);
  const isLoading = useSelector(stateManager.isLoading);
  return (
    <React.Fragment>
      <DocumentHead/>
      <Spin spinning={isLoading} size={'large'} style={{ maxHeight: '100%', zIndex: 9000 }}>
        <div>
          <PrivatePage>
            <Switch>
              <RouteWrapper path="/no-permission" exact={true} layoutConfig={noPermissionLayoutConfig}
                            component={NoPermissionLayout}/>

              {privateRoutes}
              <Route path="*" component={NotFoundPage}/>
            </Switch>
          </PrivatePage>
        </div>
      </Spin>
      <ToastMessage/>
    </React.Fragment>
  );
}
