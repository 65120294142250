import {MODULE_STATE_NAME} from './constants'

// Actions
export const SET_GLOBAL_LOADING = `${MODULE_STATE_NAME}/SET-GLOBAL-LOADING`
export const SET_NOTIFY = `${MODULE_STATE_NAME}/SET-NOTIFY`
export const SET_LAYOUT_CONFIG = `${MODULE_STATE_NAME}/SET-LAYOUT-CONFIG`
export const SET_LAYOUT_CONFIG_BREADCRUMB = `${MODULE_STATE_NAME}/SET-LAYOUT-CONFIG-BREADCRUMB`
export const GET_CURRENT_USER = `${MODULE_STATE_NAME}/GET-CURRENT-USER`
export const RESET_USER = `${MODULE_STATE_NAME}/RESET-USER`
export const GET_CURRENT_USER_SUCCESS = `${MODULE_STATE_NAME}/GET-CURRENT-USER-SUCCESS`
export const GET_CURRENT_USER_ERROR = `${MODULE_STATE_NAME}/GET-CURRENT-USER-ERROR`
export const UPDATE_CURRENT_USER = `${MODULE_STATE_NAME}/UPDATE-CURRENT-USER`
export const UPDATE_CURRENT_USER_SUCCESS = `${MODULE_STATE_NAME}/UPDATE-CURRENT-USER-SUCCESS`
export const UPDATE_CURRENT_USER_ERROR = `${MODULE_STATE_NAME}/UPDATE-CURRENT-USER-ERROR`
