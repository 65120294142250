import loadableWrapper from '../../utils/loadable';

const CustomersRoutes = [{
  path: `/warranties`,
  exact: true,
  component: loadableWrapper(() => import('./components')),
  layoutConfig: {
    isUsedAppShell: true,
    title: 'Warranties',
    routes: [
      {
        breadcrumbName: 'Warranties',
      },
    ],
  },
}];

export default CustomersRoutes;
