import _isString from 'lodash/fp/isString'
import {sendPureRequest} from './request'


const DEFAULT_ERROR = `Something went wrong, please contact support@ctrwow.com for further assist.`

export const onError = error => {
  if (error.status === 401) {
    // eslint-disable-next-line no-throw-literal
    throw 'unauthorized'
  }

  if (error.status === 403 && error.statusText === "Forbidden") {
    setTimeout(() => window.location.replace('/no-permission?expired=true'), 100)
    // eslint-disable-next-line no-throw-literal
    throw 'Your session has been expired, please login to continue.'
  }


  if (_isString(error)) {
    // eslint-disable-next-line no-throw-literal
    throw {message: error}
  }

  if (error && error.response && error.response.json) {
    return error.response.json()
      .catch(e => {
        console.log(e)
        // eslint-disable-next-line no-throw-literal
        throw {message: DEFAULT_ERROR}
      })
      .then(res => Promise.reject(res))
  }

  // eslint-disable-next-line no-throw-literal
  throw {message: DEFAULT_ERROR}
}

const parseRequestBody = (data, options) => {
  try {
    return !data ? {} : {
      body: JSON.stringify(data),
      headers: {
        ...options.headers,
        'Content-Type': 'application/json'
      }
    }
  } catch (e) {
    return {}
  }
}

export default {
  post: (url, params, data, options = {}) => sendPureRequest(url, params, {
    ...options,
    method: 'POST',
    ...parseRequestBody(data, options)

  }).catch(onError),
  get: (url, params, data, options) =>
    sendPureRequest(url, params, {
      ...options,
      method: 'GET'
    }).catch(onError)
}
