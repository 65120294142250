import * as stateManager from 'appStateManager';
import React from 'react';

import { useSelector } from 'react-redux';

import MainHeader from './Header';

import Layout, { Content } from 'ctrwowShared/components/ui/Layout';
import LeftSide from './LeftSide';


const AppShellLayout = ({ children }) => {

  const { disableMarginBody, isUsedAppShell = true } = useSelector(stateManager.getLayoutConfig) || {};

  return !isUsedAppShell ? children : (
    <Layout style={{ height: '100vh' }}>
      <LeftSide/>
      <Layout style={{ overflowY: 'auto' }}>
        <MainHeader/>
        <Content>
          <div className={disableMarginBody ? '' : 'dfoMar__base'}>
              {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

AppShellLayout.propTypes = {};

export default AppShellLayout;



