import * as types from './types'

// Action Creators
export function authenticate(token) {
  return {type: types.AUTHENTICATE, payload: {token}}
}

export function authenticateSuccess() {
  return {type: types.AUTHENTICATE_SUCCESS}
}

export function authenticateError() {
  return {type: types.AUTHENTICATE_ERROR}
}


export function logout(callback) {
  return {type: types.LOGOUT, payload: {callback}}
}
