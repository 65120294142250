import _compact from 'lodash/fp/compact'
import _startsWith from 'lodash/fp/startsWith'
import _invoke from 'lodash/fp/invoke'
import _without from 'lodash/fp/without'
import _lowerCase from 'lodash/fp/lowerCase'
import _get from 'lodash/fp/get'
import {useLocation} from 'react-router-dom'
// import usePermissionCheck from 'crm-lite/hooks/usePermissionCheck'
import React, {useCallback, useMemo, useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {push} from 'connected-react-router'
import { useDispatch } from 'react-redux'

import Menu from 'ctrwowShared/components/ui/Menu'

import MainMenuItem from './MainMenuItem'
import {findSelectedKeys, getItemKey, findItemByKey} from './helpers'
// import {SIDEBAR_ITEMS} from '../../constants'
import styles from './MainMenu.module.scss'


const useOpenKeyHook = (selectedKeys, mode) => {
  const [openKeys, setOpenKeys] = useState(mode === 'inline' && selectedKeys.length > 1 ? [selectedKeys[0]] : [])
  const openSubMenu = useCallback((newOpenKeys) => {
    setOpenKeys(_without(openKeys, newOpenKeys))
  }, [openKeys, setOpenKeys])

  return [openKeys, openSubMenu]
}


const MainMenu = ({SIDEBAR_ITEMS, isTop, isSideBarMini, onClicked, className }) => {
  const mode = useMemo(() => isTop ? 'horizontal'
    : isSideBarMini ? 'vertical' : 'inline', [isTop, isSideBarMini])

  const dispatch = useDispatch()
  const {pathname, search} = useLocation()
  const selectedKeys = useMemo(() => findSelectedKeys(SIDEBAR_ITEMS, pathname, search), [pathname, search, SIDEBAR_ITEMS])
  // const {canUseFn} = usePermissionCheck()
  const canUseFn = () => true
  const [openKeys, onOpenChange] = useOpenKeyHook(selectedKeys || [], mode)

  const onSelect = useCallback(({key, domEvent}) => {
    if (_startsWith('actionMenu', key)) {
      const item =  findItemByKey(SIDEBAR_ITEMS, key)
      _invoke('fnAction', item)

    } else if (_lowerCase(_get('target.tagName', domEvent)) !== 'a' && _startsWith('/', key)) {
      dispatch(push(key))
    }

    onClicked && onClicked()
  }, [onClicked])

  const renderItem = useCallback((menuItem, isSubItem) => {
    const commonProps = {isSideBarMini, mode, isSubItem, menuItem}
    const itemKey = getItemKey(menuItem)

    if (!menuItem.children) {
      const canUse = menuItem.permissionRules ? canUseFn(menuItem.permissionRules) : true
      return canUse
        ? (<Menu.Item
          {...menuItem.menuProps}
          className={styles.ctrComp__menuItem}

          key={itemKey}>
          <MainMenuItem {...commonProps}/></Menu.Item>)
        : null
    }

    const childrenElements = _compact(menuItem.children.map((item) => renderItem(item, true)))

    return childrenElements.length
      ? (<Menu.SubMenu
        {...menuItem.menuProps}
        className={classNames({[styles.ctrComp__subMenu__active]: selectedKeys.indexOf(itemKey) > -1})}
        popupClassName={styles.ctrComp}
        key={itemKey}

        title={<MainMenuItem {...commonProps}/>}>{childrenElements}</Menu.SubMenu>)
      : null

  }, [mode, selectedKeys])

  useEffect(() => {
    isSideBarMini && onOpenChange([])
  }, [isSideBarMini])

  return (<Menu
    mode={mode}
    onClick={onSelect}
    // inlineIndent={20}
    openKeys={openKeys}
    onOpenChange={onOpenChange}
    className={classNames(className, styles.ctrComp, styles[`ctrComp--${mode}`])}
    selectedKeys={selectedKeys}
  >{SIDEBAR_ITEMS.map(item => renderItem(item))}</Menu>)

}

MainMenu.propTypes = {
  isTop: PropTypes.bool,
  onClicked: PropTypes.func,
  isSideBarMini: PropTypes.bool,
  className: PropTypes.string
}

export default MainMenu



