import {useLocation} from 'react-router'
import {actions} from './store'
import React, {useCallback} from 'react'
import Button from 'ctrwowShared/components/ui/Button'
import Card from 'ctrwowShared/components/ui/Card'
import CtrImg from 'ctrwowShared/components/ui/CtrImg'
import noPermission from 'assets/img/403NoPermission.png'
import {useDispatch} from 'react-redux'


const NoPermission = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const expired = location && location.search && location.search.indexOf('expired')
  console.log(location)
  const onLogout = useCallback(() => {
    dispatch(actions.logout())
  }, [dispatch])

  return (<>
    <div style={{margin: '40px'}}>
      <Card className={'dfoTextAlign--center'}>
        <CtrImg src={noPermission} style={{maxWidth: '200px'}}/>
        <h2>{expired ? 'Token expired' : 'Permission denied'}</h2>
        {expired
          ? <p>Your session has been expired, please login to continue.</p>
          : <p>You do not have permission to access this page. <br/> Please refer to your system administrator.</p>
        }

        <Button onClick={onLogout} size={'large'} type={'primary'}>{expired ? 'Login' : 'Sign in with another account' }</Button>
      </Card>
    </div>
  </>)
}

NoPermission.propTypes = {}

export default NoPermission



