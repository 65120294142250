import * as types from './types'
import * as stateManager from './stateManager'

// Reducer
export default function reducer(state = stateManager.initState(), {type, payload}) {
  switch (type) {
    case types.AUTHENTICATE:
      // Perform action
      return state
    case types.AUTHENTICATE_SUCCESS:
      // Perform action
      return state
    case types.AUTHENTICATE_ERROR:
      // Perform action
      return state
    default: return state;
  }
}