import React, {useCallback} from 'react'
import {LogoutOutlined} from '@ant-design/icons'
import Menu from 'ctrwowShared/components/ui/Menu'
import {useDispatch} from 'react-redux'
import {actions} from 'containers/AppCore/Authentication'
import styles from './DropdownItems.module.scss'

const DropdownItems = () => {
  const dispatch = useDispatch()
  const onLogout = useCallback(() => {
    dispatch(actions.logout())
  }, [dispatch])

  return (
    <div className={styles.ctrComp}>
      <Menu inlineIndent={16} onClick={onLogout}>
        <Menu.Item> <LogoutOutlined/> Logout</Menu.Item>
      </Menu>
    </div>
  )
}

export default DropdownItems



