import React from 'react'
import classNames from 'classnames'
import OwnerInfo from 'ctrwowShared/components/shared/OwnerInfo'
import {useSelector} from 'react-redux'
import * as appStateManager from 'appStateManager'
import styles from './UserName.module.scss'


const UserName = ({isMini}) => {
  const user = useSelector(appStateManager.getCurrentUser)
  return <OwnerInfo owner={user && user.user} className={classNames(styles.ctrComp, isMini ? styles['ctrComp--mini'] :  null)}/>
}

export default UserName
