export const setUserToStorage = (userInfo) => {
  try {
    window.localStorage.setItem('currentUser', JSON.stringify(userInfo))
  } catch (e) {
    console.log(e)
  }
}

export const getUserFromStorage = () => {
  try {
    return JSON.parse(window.localStorage.getItem('currentUser'))
  } catch (e) {
    console.log(e)
    return null
  }
}

export const removeUserFromStorage = () => {
  try {
    return window.localStorage.removeItem('currentUser')
  } catch (e) {
    console.log(e)
    return null
  }
}
