import CtrDashboardIcon from 'ctrwowShared/components/ui/Icon/dfoIcon/CtrDashboardIcon'
import DfoUserManagementIcon from 'ctrwowShared/components/ui/Icon/dfoIcon/DfoUserManagementIcon'
import CtrManageIcon from 'ctrwowShared/components/ui/Icon/dfoIcon/CtrManageIcon'
import CtrMediaIcon from 'ctrwowShared/components/ui/Icon/dfoIcon/CtrMediaIcon'
import CtrProductIcon from 'ctrwowShared/components/ui/Icon/dfoIcon/CtrProductIcon'
import CtrOrderIcon from 'ctrwowShared/components/ui/Icon/dfoIcon/CtrOrderIcon'


export const SIDEBAR_ITEMS = [
  {
    labelId: 'app.mainMenu.dashboard',
    icon: CtrDashboardIcon,
    path: '/'
  },
  {
    labelId: 'app.mainMenu.manage',
    icon: CtrManageIcon,
    key:'manage_group',
    children: [
      {
        labelId: 'app.mainMenu.manage.products',
        icon: CtrProductIcon,
        path: '/products'
      },
      {
        labelId: 'app.mainMenu.manage.warranties',
        icon: CtrOrderIcon,
        path: '/warranties'
      },
      {
        labelId: 'app.mainMenu.manage.reviews',
        icon: DfoUserManagementIcon,
        path: '/reviews'
      },
      {
        labelId: 'app.mainMenu.manage.proofOfReview',
        icon: CtrMediaIcon,
        path: '/proof-of-reviews'
      },
      // {
      //   labelId: 'app.mainMenu.manage.users',
      //   icon: DfoUserManagementIcon,
      //   path: '/users'
      // }
    ]
  },
  // {
  //   labelId: 'app.mainMenu.reports',
  //   icon: CtrAnalyticsIcon,
  //   path: '/reports'
  // }
]
