import CONFIGURABLE_CONSTANTS from 'constants/configurable.constants'
import {call, put, takeLatest} from '@redux-saga/core/effects'
import {setNotify, setCurrentUser, resetUser} from 'appActions'

import * as types from './types'
import {exchangeToken, getPreAuthInfoFetcher} from 'fetchers/auth.fetchers'
import {getAuthenticateUrl, getLogoutUrl} from './getAuthenticateUrl'


function* sendAuthenticateRequest({payload: {token}}) {
  try {
    console.log("authenticate")
    const re = yield call(exchangeToken, {token, applicationId: CONFIGURABLE_CONSTANTS.DFO_WR_MANAGER_SSO_APP_ID})

    if (!re || !re.token) {
      // eslint-disable-next-line no-throw-literal
      throw Error('Invalid user')
    }

    const userInfo = yield call(getPreAuthInfoFetcher, re.token)

    yield put(setCurrentUser(userInfo))
  } catch (e) {
    console.log(e)
    if (e === 'unauthorized') {
      // yield push('/unauthorized')
      yield put(setCurrentUser('unauthorized'))
    } else {
      yield put(setNotify('error', e))
      setTimeout(() => window.location = getAuthenticateUrl(), 1000)
    }
  }
}

function* watchLogoutAction() {
  yield put(resetUser(() => {
    const path = getLogoutUrl()
    console.log(path)
    window.location = path
  }))

}

export default function* rootSaga() {
  yield takeLatest(types.AUTHENTICATE, sendAuthenticateRequest)
  yield takeLatest(types.LOGOUT, watchLogoutAction)
}
