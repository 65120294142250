import CONFIGURABLE_CONSTANTS from 'constants/configurable.constants'
import pureFetcher from './pureFetcher'


const getPreAuthHeaders = (token) => ({headers: {'X_PREAUTH': token}})

export const getPreAuthInfoFetcher = token => pureFetcher
  .post(`${CONFIGURABLE_CONSTANTS.DFO_WR_MANAGER_BASE_URL}/authen`, null, null, getPreAuthHeaders(token))

export const exchangeToken = (payload) => pureFetcher
  .post(`${CONFIGURABLE_CONSTANTS.DFO_WR_MANAGER_SSO_API_URL}/oauth/exchangeToken`, null, payload)

