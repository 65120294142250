import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import LanguageProvider from './components/LanguageProvider';
import history from './utils/history';
// Import i18n messages
import { translationMessages } from './i18n';
import MainApp from './containers/AppCore';

import configureStore from './configureStore';

import './App.scss';
import './App.less';
import { modulesRoutes } from './pages';

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);


function App() {
  return (
    <Provider store={store}>
      <LanguageProvider messages={translationMessages}>
        <ConnectedRouter history={history}>
          <MainApp pageRoutes={modulesRoutes}/>
        </ConnectedRouter>
      </LanguageProvider>
    </Provider>
  );
}

export default App;
