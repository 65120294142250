import loadableWrapper from '../../utils/loadable';

const ProductsRoutes = [{
  path: `/products`,
  exact: true,
  component: loadableWrapper(() => import('./components')),
  layoutConfig: {
    isUsedAppShell: true,
    title: 'Product List',
    routes: [
      {
        breadcrumbName: 'Products',
      },
    ],
  },
}];

export default ProductsRoutes;
