import Progress from 'ctrwowShared/components/ui/Progress'
import {Text} from 'ctrwowShared/components/ui/Typography'
import React, {useEffect, useState} from 'react'


const Progressing = () => {
  const [percent, set_percent] = useState(1)

  useEffect(() => {
    let step = 1

    const processing = setInterval(() => {
      step += 1

      if (step >= 100) {
        clearInterval(processing)
      } else {
        set_percent(step)
      }
    }, 100)

    return () => clearInterval(processing)

  }, [])

  return <div>
    <Progress percent={percent} steps={50} size="large" active strokeColor="#52c41a"/><br/>
    <Text level={3}>Authorizing...</Text>
  </div>
}


export default Progressing
