import _get from 'lodash/fp/get'
import {createSelector} from 'reselect'
import produce from 'immer'
import {MODULE_STATE_NAME} from './constants'
import _setFp from 'lodash/fp/set'
// convert to mutable
const _set = _setFp.convert({'immutable': false})



const STATE_DEFAULT_VALUE = {}

export const initState = () => ({...STATE_DEFAULT_VALUE})
export const resetState = () => ({...STATE_DEFAULT_VALUE})

/******************************** GETTER ********************************/
const getLocalState = (state) => _get(state, [MODULE_STATE_NAME]) || initState()
// const getLocalState = _getOr(initState(), MODULE_STATE_NAME)

export const getData = createSelector(
  getLocalState,
  _get(['filterValues', 'queryString'])
)


/******************************** UPDATER ********************************/

export const updaterTableSettings = (state, tableSettings) => produce(state, _set('tableSettings', tableSettings))

