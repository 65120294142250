import {stateManager} from 'containers/AppCore/store'
class APIPreAuthHeader {
  get authenticate() {
    const token = localStorage.getItem('PRE_AUTH_TOKEN');
    if (token) {
      this.privateContent = token;
    }
    return this.privateContent;
  }
  set authenticate(newContent) {
    if (newContent) {
      this.privateContent = newContent;
    }
  }
}


class APIHeader {
  get authenticate() {
    const token = stateManager.getUserToken();
    if (token) {
      this.privateContent = 'Bearer ' + token;
    }
    return this.privateContent;
  }

  get rawToken() {
    const token = localStorage.getItem('userToken');
    if (token) {
      return JSON.parse(token);
    }
    return null
  }

  set authenticate(newContent) {
    if (newContent) {
      this.privateContent = newContent;
    }
  }
}

export const HeaderSetting = new APIHeader();
export const PreAuthHeaderSetting = new APIPreAuthHeader();
