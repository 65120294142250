import DashboardLayout from './DashboardLayout.Loadable'


const ROUTES = [{
  path: `/`,
  exact: true,
  component: DashboardLayout,
  layoutConfig: {
    isUsedAppShell: true,
    title: 'Dashboard',
    routes: [
      {
        breadcrumbName: 'Dashboard'
      }
    ]
  }
}]

export default ROUTES
