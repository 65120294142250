import React from 'react'
import classNames from 'classnames'
import Dropdown from 'ctrwowShared/components/ui/Dropdown'
import UserName from './UserName'
import DropdownItems from './DropdownItems'

import styles from './UserInfo.module.scss'



const UserInfo = ({isMini}) => {
  return (
    <Dropdown
      overlay={<DropdownItems />}
      placement="topLeft" arrow
    >

      <div className={classNames(styles.ctrComp, isMini ? styles['ctrComp--mini'] :  null)}>
        <UserName isMini={isMini}/>
      </div>
    </Dropdown>)
}

UserInfo.propTypes = {}

export default UserInfo



