import _map from 'lodash/fp/map'
import {Link} from 'react-router-dom'
import {stateManager} from 'containers/AppCore/store'
import React, {useMemo} from 'react'
import {Header} from 'ctrwowShared/components/ui/Layout'
import Breadcrumb from 'ctrwowShared/components/ui/Breadcrumb'
import {useSelector} from 'react-redux'

import styles from './Header.module.scss'
// import { Layout, Menu, Breadcrumb } from 'antd';

// const { Header, Content, Footer, Sider } = Layout;

const HeaderWrapper = () => {

  const breadcrumb = useSelector(stateManager.getLayoutConfig__breadcrumb)

  console.log({breadcrumb})

  const items = useMemo(() => {

    return _map(({breadcrumbName, path}, index) => {
      const item = path ? {component: Link, props: {to: path}} : {component: React.Fragment, props: {}}
      return <Breadcrumb.Item key={path || breadcrumbName}>
        <item.component {...item.props} >{breadcrumbName}</item.component>
      </Breadcrumb.Item>
    }, breadcrumb)

  }, [breadcrumb])

  return !breadcrumb ? null :
    (<Header className={styles.ctrComp} style={{padding: 0}}>
      <Breadcrumb className={styles.ctrComp__breadcrumb}>
        {items}
      </Breadcrumb>
    </Header>)
}

HeaderWrapper.propTypes = {}

export default HeaderWrapper



