/* eslint-disable react/prop-types */
import React from 'react'
import {Link} from 'react-router-dom'

import styles from './Logo.module.scss'

import classNames from 'classnames'


function Logo({homePage, isMini, className}) {
  return (
    <Link to={homePage || '/'} className={classNames(className, styles.ctrComp, {[styles.ctrComp__mini]: isMini})}>
      <div className={styles.ctrComp__image}>
        {isMini && 'DFO WRM'}
        {!isMini && 'DFO WR Manager'}
      </div>
    </Link>
  )
}

export default Logo
