import _startsWith from 'lodash/fp/startsWith'
import _isArray from 'lodash/fp/isArray'
import _isObject from 'lodash/fp/isObject'
import _map from 'lodash/fp/map'

const findSelectedItems = (menus, pathname, search='') => {
  if (!menus || !menus.length) {
    return null
  }

  const isSelectedItem = ({path}) => search ? (path === pathname + search) : path === '/' ? path === pathname : _startsWith(path, pathname)

  let index = 0
  let found = []

  while (index < menus.length) {
    let item = menus[index]
    if (isSelectedItem(item)) {
      found.push(item)
    }
    if (item.children) {
      const childItem = findSelectedItems(item.children, pathname, search)
      if (childItem.length) {
        found.push(item)
        found = [...found, ...childItem]
      }
    }

    index++
  }
  return found;
}

export const getItemKey = ({key, path}) => path || key

export const findSelectedKeys = (menus, pathname, search) =>  _map(getItemKey, findSelectedItems(menus, pathname, search))


export const findItemByKey = (menus, key) => {
  if (!menus) {
    return null
  }

  if (_isArray(menus)) {
    let found = null
    let index = menus.length
    while(index--) {
      found = findItemByKey(menus[index], key)
      if (found) {
        break
      }
    }

    return found
  } else if (_isObject(menus)) {
    if (getItemKey(menus) === key) {
      return menus
    } else if (menus.children) {
      return findItemByKey(menus.children, key)
    }
  }

  return null
}
