import loadableWrapper from '../../utils/loadable';

const ReviewsRoutes = [{
  path: `/reviews`,
  exact: true,
  component: loadableWrapper(() => import('./components/List')),
  layoutConfig: {
    isUsedAppShell: true,
    title: 'Reviews',
    routes: [
      {
        breadcrumbName: 'Reviews',
      },
    ],
  },
}];

export default ReviewsRoutes;
